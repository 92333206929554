export const socialIconList = [
  {
    name: 'twitter',
    prefix: 'fab', // fab prefix for brands, fas for solid icons
    link: 'https://twitter.com/kishorliv',
  },
  {
    name: 'github',
    prefix: 'fab',
    link: 'https://github.com/kishorliv',
  },
  {
    name: 'linkedin',
    prefix: 'fab',
    link: 'https://www.linkedin.com/in/kishorneupane',
  },
];
