import React from 'react';

import useDarkMode from 'use-dark-mode';

const ThemeToggle = () => {
  const darkMode = useDarkMode(false);

  return (
    <div className="dark-mode-toggle">
      <span 
        role="img" 
        aria-label="dark mode toggle emojis" 
        onClick={darkMode.toggle} 
        style={{ 'cursor': 'pointer', 'color': 'transparent', 'textShadow': '0 0 0 #fff'}}>
        {darkMode.value && '🔆'}
        {!darkMode.value && '🌙'}
      </span>
    </div>
  );
};

export default ThemeToggle;
